<template>
  <footer class="footer py-3 bg-dark border-top">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-8">
          <span class="text-muted small"><i>This website was built using Vue.js, and Bootstrap. It is hosted on AWS with an EC2 server. I plan to create web applications using Azure as well, hosted under a subdomain of matthewscanland.com.</i></span>
        </div>
        <div class="col-md-4 text-end">
          <span class="text-muted small"><i>Please contact me for any questions: <a href="mailto:mkscanland@gmail.com">mkscanland@gmail.com</a></i></span>
        </div>
      </div>
    </div>
  </footer>
</template>