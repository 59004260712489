<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark sticky-top">
    <div class="container-fluid">
      <a class="navbar-brand">Matthew Scanland</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="/">Home</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="/files/Scanland-Matthew_Resume.pdf" data-bs-toggle="dropdown">
              Resume
            </a>
            <div class="dropdown-menu">
              <a class="dropdown-item" href="/files/Scanland-Matthew_Resume.pdf">Resume (pdf)</a>
              <a class="dropdown-item" href="/files/Scanland-Matthew_Resume.docx">Resume (docx)</a>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="/#significantProjects" data-bs-toggle="dropdown">
              Portfolio
            </a>
            <div class="dropdown-menu">
              <a class="dropdown-item" href="/rebuild">Internal Website Rebuild</a>
              <a class="dropdown-item" href="/randomforest">Random Forest</a>
              <a class="dropdown-item" href="/validations">Lab Validations</a>
              <a class="dropdown-item" href="/webapps">Other Web Apps</a>
              <a class="dropdown-item" href="/itsystems">IT Systems</a>
              <a class="dropdown-item" href="https://github.com/mkscanland">GitHub</a>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="/#significantProjects" data-bs-toggle="dropdown">
              Guides
            </a>
            <div class="dropdown-menu">
              <a class="dropdown-item" href="/files/Azure-Data-Lake-Plan_Public Copy.pdf">Azure Data Lake Creation</a>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="/#contact" data-bs-toggle="dropdown">
              Contact
            </a>
            <div class="dropdown-menu">
              <a class="dropdown-item" href="https://www.linkedin.com/in/matthew-scanland/">LinkedIn</a>
              <a class="dropdown-item" href="mailto:mkscanland@gmail.com">Email</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>